<script setup lang="ts">
const items = [
  {
    title: 'JavaScript',
    logo: '/images/webp/JavaScript.webp',
    link: 'https://developer.mozilla.org/de/docs/Web/JavaScript'
  },
  {
    title: 'TypeScript',
    logo: '/images/svg/TypeScript.svg',
    link: 'https://www.typescriptlang.org/'
  },
  {
    title: 'Vue.js',
    logo: '/images/svg/Vuejs.svg',
    link: 'https://vuejs.org/'
  },
  {
    title: 'Nuxt',
    logo: '/images/svg/nuxt.svg',
    link: 'https://nuxtjs.org/'
  },
  {
    title: 'Docker',
    logo: '/images/svg/docker.svg',
    link: 'https://www.docker.com/'
  },
  {
    title: 'Strapi',
    logo: '/images/svg/strapi.svg',
    link: 'https://strapi.io/'
  },
  {
    title: 'aws',
    logo: '/images/svg/aws.svg',
    link: 'https://aws.amazon.com/de/'
  },
  {
    title: 'ParseServer',
    logo: '/images/webp/parse-server.webp',
    link: 'https://parseplatform.org/'
  },
  {
    title: 'Supabase',
    logo: '/images/svg/supabase.svg',
    link: 'https://supabase.io/'
  }
]
</script>

<template>
  <div class="mt-100 px-6">
    <v-row>
      <v-col cols="12">
        <div>
          <!-- <svg class="d-inline stroke-blue" width="80px" height="22px">
            <path d="M11 11 L60 11" stroke-linecap="round" stroke-width="20" />
          </svg> -->
          <h2 class="text-h2 pb-3">Unser Tech-Stack</h2>
        </div>
      </v-col>
      <v-col cols="12" class="pb-16">
        <v-row class="mx-16">
          <v-col v-for="item in items" :key="item.title" cols="12" md="6" lg="4" class="my-4">
            <NuxtLink :to="item.link" target="_blank">
              <v-img :src="item.logo" alt="logo" :max-height="50" />
            </NuxtLink>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped lang="scss">
.stroke-blue {
  stroke: #14213d;
}
</style>
